import {
    ADD_MANUFACTURER_REQUEST,
    ADD_MANUFACTURER_SUCCESS,
    ADD_MANUFACTURER_FAILURE,
    UPDATE_MANUFACTURER_REQUEST,
    UPDATE_MANUFACTURER_SUCCESS,
    UPDATE_MANUFACTURER_FAILURE,
    DELETE_MANUFACTURER_REQUEST,
    DELETE_MANUFACTURER_SUCCESS,
    DELETE_MANUFACTURER_FAILURE,
    FETCH_MANUFACTURERS_REQUEST,
    FETCH_MANUFACTURERS_SUCCESS,
    FETCH_MANUFACTURERS_FAILURE,
    ADD_FLOOR_REQUEST,
    ADD_FLOOR_SUCCESS,
    ADD_FLOOR_FAILURE,
    UPDATE_FLOOR_REQUEST,
    UPDATE_FLOOR_SUCCESS,
    UPDATE_FLOOR_FAILURE,
    DELETE_FLOOR_REQUEST,
    DELETE_FLOOR_SUCCESS,
    DELETE_FLOOR_FAILURE,
} from '../actionTypes';

// Manufacturer actions
export const fetchManufacturersRequest = () => ({
    type: FETCH_MANUFACTURERS_REQUEST,
});

export const fetchManufacturersSuccess = (manufacturers) => ({
    type: FETCH_MANUFACTURERS_SUCCESS,
    payload: manufacturers,
});

export const fetchManufacturersFailure = (error) => ({
    type: FETCH_MANUFACTURERS_FAILURE,
    payload: error,
});

export const addManufacturerRequest = () => ({
    type: ADD_MANUFACTURER_REQUEST,
});

export const addManufacturerSuccess = (manufacturer) => ({
    type: ADD_MANUFACTURER_SUCCESS,
    payload: { manufacturer },
});

export const addManufacturerFailure = (error) => ({
    type: ADD_MANUFACTURER_FAILURE,
    payload: { error },
});

// Floor actions
export const addFloorRequest = () => ({
    type: ADD_FLOOR_REQUEST,
});

export const addFloorSuccess = (manufacturer, floor) => ({
    type: ADD_FLOOR_SUCCESS,
    payload: { manufacturer, floor },
});

export const addFloorFailure = (error) => ({
    type: ADD_FLOOR_FAILURE,
    payload: { error },
});

export const updateFloorRequest = () => ({
    type: UPDATE_FLOOR_REQUEST,
});

export const updateFloorSuccess = (manufacturer, oldFloorName, updatedFloor) => ({
    type: UPDATE_FLOOR_SUCCESS,
    payload: { manufacturer, oldFloorName, updatedFloor },
});

export const updateFloorFailure = (error) => ({
    type: UPDATE_FLOOR_FAILURE,
    payload: { error },
});

// Request action
export const deleteFloorRequest = () => ({
    type: DELETE_FLOOR_REQUEST,
});

// Success action
export const deleteFloorSuccess = (manufacturer, floorName) => ({
    type: DELETE_FLOOR_SUCCESS,
    payload: { manufacturer, floorName },
});

// Failure action
export const deleteFloorFailure = (error) => ({
    type: DELETE_FLOOR_FAILURE,
    payload: error,
});

export const deleteManufacturerRequest = () => ({
    type: DELETE_MANUFACTURER_REQUEST,
  });
  
  export const deleteManufacturerSuccess = (manufacturerId) => ({
    type: DELETE_MANUFACTURER_SUCCESS,
    payload: manufacturerId,
  });
  
  export const deleteManufacturerFailure = (error) => ({
    type: DELETE_MANUFACTURER_FAILURE,
    payload: error,
  });
  
  export const updateManufacturerRequest = () => ({
    type: UPDATE_MANUFACTURER_REQUEST,
  });
  
  export const updateManufacturerSuccess = (manufacturer) => ({
    type: UPDATE_MANUFACTURER_SUCCESS,
    payload: manufacturer,
  });
  
  export const updateManufacturerFailure = (error) => ({
    type: UPDATE_MANUFACTURER_FAILURE,
    payload: error,
  });