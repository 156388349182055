import {
    addManufacturerRequest,
    addManufacturerSuccess,
    addManufacturerFailure,
    addFloorRequest,
    addFloorSuccess,
    addFloorFailure,
    updateFloorRequest,
    updateFloorSuccess,
    updateFloorFailure,
    deleteFloorRequest,
    deleteFloorSuccess,
    deleteFloorFailure,
    fetchManufacturersFailure,
    fetchManufacturersRequest,
    fetchManufacturersSuccess,
    deleteManufacturerRequest,
    deleteManufacturerSuccess,
    deleteManufacturerFailure,
    updateManufacturerRequest,
    updateManufacturerSuccess,
    updateManufacturerFailure,
} from '../floorAction';

import { addNotification } from '../notificationAction'; // Assuming you have notification actions
import axiosConfig from '../../../utils/axiosConfig'; // Assuming axiosConfig is set up correctly
const apiUrl = process.env.REACT_APP_API_URL; // Assuming this is where your API URL is stored

// Thunk to fetch manufacturers
export const fetchManufacturers = () => async (dispatch) => {
    console.log("haetaan vcalmistajia");
    dispatch(fetchManufacturersRequest());
    dispatch(addNotification('manufacturers', 'loading', 'Haetaan valmistajia...'));

    try {
        const response = await axiosConfig.get(`${apiUrl}/manufacturers`);

        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }

        const manufacturers = response.data;
        dispatch(fetchManufacturersSuccess(manufacturers));
        dispatch(addNotification('manufacturers', 'success', 'Valmistajat haettu onnistuneesti'));
    } catch (error) {
        dispatch(fetchManufacturersFailure(error.message));
        dispatch(addNotification('manufacturers', 'fail', 'Valmistajien haku epäonnistui'));
    }
};

// Thunk to add a manufacturer
export const addManufacturer = (manufacturer) => async (dispatch) => {
    dispatch(addNotification('manufacturer', 'loading', 'Lisätään valmistajaa'));
    dispatch(addManufacturerRequest());
    console.log("lisäätä valmistaha", manufacturer);
    try {
        const response = await axiosConfig.post(`${apiUrl}/manufacturers`, { manufacturer });
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        const newManufacturer = response.data;
        dispatch(addManufacturerSuccess(newManufacturer));
        dispatch(addNotification('manufacturer', 'success', 'Valmistaja lisätty onnistuneesti'));
    } catch (error) {
        dispatch(addManufacturerFailure(error.message));
        dispatch(addNotification('manufacturer', 'fail', 'Valmistajan lisääminen epäonnistui'));
    }
};

// Thunk to add a floor
export const addFloor = (manufacturer, floor) => async (dispatch) => {
    dispatch(addNotification('floor', 'loading', 'Lisätään sävyä valmistajalle'));
    dispatch(addFloorRequest());
    console.log("lisätään", manufacturer, floor);
    try {
        const response = await axiosConfig.post(`${apiUrl}/manufacturers/${manufacturer.manufacturer}/floors`, floor);
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        const newFloor = response.data;
        dispatch(addFloorSuccess(manufacturer, newFloor));
        dispatch(addNotification('floor', 'success', 'Sävy lisätty valmistajalle onnistuneesti'));
    } catch (error) {
        dispatch(addFloorFailure(error.message));
        dispatch(addNotification('floor', 'fail', 'Sävyn lisääminen epäonnistui'));
    }
};

// Thunk to update a floor
export const updateFloor = (manufacturer, oldFloorName, updatedFloor) => async (dispatch) => {
    dispatch(addNotification('floor', 'loading', 'Päivitetään sävyn tietoja'));
    dispatch(updateFloorRequest());
    console.log(manufacturer, oldFloorName, updatedFloor);
    try {
        const response = await axiosConfig.put(
            `${apiUrl}/manufacturers/${manufacturer.manufacturer}/floors/${oldFloorName.floor}`,
            updatedFloor
        );
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        const updatedFloorData = response.data;
        dispatch(updateFloorSuccess(manufacturer, oldFloorName, updatedFloorData));
        dispatch(addNotification('floor', 'success', 'Sävyn tiedot päivitettiin onnistuneesti'));
    } catch (error) {
        dispatch(updateFloorFailure(error.message));
        dispatch(addNotification('floor', 'fail', 'Sävyn päivitys epäonnistui'));
    }
};

// Thunk to delete a floor
export const deleteFloor = (manufacturer, floorName) => async (dispatch) => {
    dispatch(addNotification('floor', 'loading', 'Poistetaan sävyä valmistajalta'));
    dispatch(deleteFloorRequest());
    console.log("poistetaan", manufacturer, floorName)
    try {
        const response = await axiosConfig.delete(
            `${apiUrl}/manufacturers/${manufacturer.manufacturer}/floors/${floorName.floor}`
        );
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        dispatch(deleteFloorSuccess(manufacturer, floorName));
        dispatch(addNotification('floor', 'success', 'Sävy poistettu valmistajalta onnistuneesti'));
    } catch (error) {
        dispatch(deleteFloorFailure(error.message));
        dispatch(addNotification('floor', 'fail', 'Sävyn poistaminen epäonnistui'));
    }
};

// Thunk to delete a manufacturer
export const deleteManufacturer = (manufacturerID) => async (dispatch) => {
    dispatch(addNotification('manufacturer', 'loading', 'Poistetaan valmistajaa'));
    dispatch(deleteManufacturerRequest());
    try {
        await axiosConfig.delete(`${apiUrl}/manufacturers/${manufacturerID}`);
        dispatch(deleteManufacturerSuccess(manufacturerID));
        dispatch(addNotification('manufacturer', 'success', 'Valmistaja poistettu onnistuneesti'));
    } catch (error) {
        dispatch(deleteManufacturerFailure(error.message));
        dispatch(addNotification('manufacturer', 'fail', 'Valmistajan poistaminen epäonnistui'));
    }
};

// Thunk to update a manufacturer
export const updateManufacturer = (manufacturerId, updatedData) => async (dispatch) => {
    dispatch(addNotification('manufacturer', 'loading', 'Päivitetään valmistajaa'));
    dispatch(updateManufacturerRequest());
    console.log("Päivitetään valmistaja", updatedData);

    try {
        const response = await axiosConfig.put(
            `${apiUrl}/manufacturers/${manufacturerId}`,
            { updatedData } // Wrap in an object
        );
        dispatch(updateManufacturerSuccess(response.data));
        dispatch(addNotification('manufacturer', 'success', 'Valmistaja päivitetty onnistuneesti'));
    } catch (error) {
        dispatch(updateManufacturerFailure(error.message));
        dispatch(addNotification('manufacturer', 'fail', 'Valmistajan päivittäminen epäonnistui'));
    }
};
