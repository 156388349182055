import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchStorage, addStorageItem, updateStorageItem, deleteStorageItem, uploadItemImage } from '../redux/actions/thunks/Storage';
import { FaCheck, FaTimes } from 'react-icons/fa';
import LoadingIcon from './LoadingIcon';
import TransactionWindow from './TransactionWindow';
import AddItem from './AddItem';
import AddHandRail from './AddHandRail';
import AddOther from './AddOther';
import ModifyProduct from './ModifyProduct';
import ModifyAmount from './ModifyAmount';
import TammiImage from '../images/Tammi.jpg';
import KoivuImage from '../images/Koivu.jpg';
import SaarniImage from '../images/Saarni.jpg';
import MDFTammiImage from '../images/MDF_Tammi.jpg';
import gearIcon from '../images/gear-icon.png';
import QuestionMarkImage from '../images/question_mark.png';

import TammiNeliö from '../images/Tammi.jpg';
import TammiNeliöUrallinen from '../images/tammi_neliö_urallinen.png';
import TammiPyöreä from '../images/tammi_pyöreä.png';
import TammiPyöreäUrallinen from '../images/tammi_pyöreä_urallinen.png';
import TammiSuorakaide from '../images/tammi_suorakaide.png';
import TammiSuorakaideUrallinen from '../images/tammi_suorakaide_urallinen.png';

import SaarniNeliö from '../images/Saarni.jpg';
import SaarniNeliöUrallinen from '../images/saarni_neliö_urallinen.png';
import SaarniPyöreä from '../images/saarni_pyöreä.png';
import SaarniPyöreäUrallinen from '../images/saarni_pyöreä_urallinen.png';
import SaarniSuorakaide from '../images/saarni_suorakaide.png';
import SaarniSuorakaideUrallinen from '../images/saarni_suorakaide_urallinen.png';

import KoivuNeliö from '../images/Koivu.jpg';
import KoivuNeliöUrallinen from '../images/koivu_neliö_urallinen.png';
import KoivuPyöreä from '../images/koivu_pyöreä.png';
import KoivuPyöreäUrallinen from '../images/koivu_pyöreä_urallinen.png';
import KoivuSuorakaide from '../images/koivu_suorakaide.png';
import KoivuSuorakaideUrallinen from '../images/koivu_suorakaide_urallinen.png';

const StoragePage = () => {
  const [showAddItem, setShowAddItem] = useState(false);
  const [showAddHandRail, setShowAddHandRail] = useState(false);
  const [showAddOther, setShowAddOther] = useState(false);
  const [showModifyProduct, setShowModifyProduct] = useState(false);
  const [showModifyAmount, setShowModifyAmount] = useState(false); // State to show ModifyAmount window
  const [selectedWoodType, setSelectedWoodType] = useState('Tammi');
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null); // State to store selected item ID for modifying amount
  const [showTransactionWindow, setShowTransactionWindow] = useState(false); // State for showing transaction log window
  const [showMessage, setShowMessage] = useState(false); // State for showing transaction log window
  const [showLoading, setShowLoading] = useState(false);
  const [Message, setMessage] = useState('');
  const [isModifyAmountMode, setIsModifyAmountMode] = useState(true);

  const notifications = useSelector(state => state.notification);
  const storage = useSelector(state => state.storage.storage);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchStorage());
  }, [dispatch]);

  useEffect(() => {
    if (notifications.message === 'Varaston haku tietokannasta epäonnistui' ||
      notifications.message === 'Tuote lisätty onnistuneesti' ||
      notifications.message === 'Tuotteen lisäys epäonnistui' ||
      notifications.message === 'Tuote päivitetty onnistuneesti' ||
      notifications.message === 'Tuotteen päivitys epäonnistui' ||
      notifications.message === 'Tuote poistettu onnistuneesti' ||
      notifications.message === 'Tuotteen poisto epäonnistui' ||
      notifications.message === 'Tapahtumien haku epäonnistui' ||
      notifications.message === 'Tapahtuman lisääminen epäonnistui' ||
      notifications.message === 'Kuva lisätty onnnistuneesti' ||
      notifications.requestStatus === 'error'
    ) {
      setMessage(notifications.message);
      setShowMessage(true);
      // Set isSubmitted to false after 5 seconds
      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
  }, [notifications]);

  useEffect(() => {
    setShowLoading(false);
    if (notifications.requestStatus === 'loading'
    ) {
      setShowLoading(true);
      // Set isSubmitted to false after 5 seconds
    }
  }, [notifications]);

  const handleShowTransactionWindow = () => {
    setShowTransactionWindow(true); // Show transaction log window
  };

  const handleModifyProduct = (item) => {
    setSelectedItem(item);
    setShowModifyProduct(true);
  };

  const handleModifyAmount = (itemId) => {
    setSelectedItemId(itemId); // Set the selected item ID
    setShowModifyAmount(true);
  };

  const handleAddItemClick = () => {
    setShowAddItem(true);
  };

  const handleAddHandRailClick = () => {
    setShowAddHandRail(true);
  };

  const handleWoodTypeClick = (woodType) => {
    setSelectedWoodType(woodType);
  };

  const handleAddOtherClick = () => {
    setShowAddOther(true);
  };

  const handleAddImageClick = (itemId) => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*';
    fileInput.addEventListener('change', (event) => {
      const file = event.target.files[0];
      if (file) {
        dispatch(uploadItemImage(itemId, file));
      }
    });
    fileInput.click();
  };

  const getHandRailImage = (selectedWoodType, handRailModel) => {
    switch (selectedWoodType) {
      case 'Tammi':
        switch (handRailModel) {
          case 'Neliö':
            return TammiNeliö;
          case 'Neliö urallinen':
            return TammiNeliöUrallinen;
          case 'Pyöreä':
            return TammiPyöreä;
          case 'Pyöreä urallinen':
            return TammiPyöreäUrallinen;
          case 'Suorakaide':
            return TammiSuorakaide;
          case 'Suorakaide urallinen':
            return TammiSuorakaideUrallinen;
          default:
            return QuestionMarkImage;
        }
      case 'Saarni':
        switch (handRailModel) {
          case 'Neliö':
            return SaarniNeliö;
          case 'Neliö urallinen':
            return SaarniNeliöUrallinen;
          case 'Pyöreä':
            return SaarniPyöreä;
          case 'Pyöreä urallinen':
            return SaarniPyöreäUrallinen;
          case 'Suorakaide':
            return SaarniSuorakaide;
          case 'Suorakaide urallinen':
            return SaarniSuorakaideUrallinen;
          default:
            return QuestionMarkImage;
        }
      case 'Koivu':
        switch (handRailModel) {
          case 'Neliö':
            return KoivuNeliö;
          case 'Neliö urallinen':
            return KoivuNeliöUrallinen;
          case 'Pyöreä':
            return KoivuPyöreä;
          case 'Pyöreä urallinen':
            return KoivuPyöreäUrallinen;
          case 'Suorakaide':
            return KoivuSuorakaide;
          case 'Suorakaide urallinen':
            return KoivuSuorakaideUrallinen;
          default:
            return QuestionMarkImage;
        }
      default:
        return QuestionMarkImage;
    }
  };



  // Group items by woodType and handRailModel or thickness
  const groupedStorage = storage.reduce((acc, item) => {
    const isPlatform = item.woodType !== 'Käsijohteet' && item.length > 1500 && item.thickness === 40 && item.depth > 900;

    // Initialize the woodType group if not present
    if (!acc[item.woodType]) {
      acc[item.woodType] = {};
    }

    // Special grouping for 'Käsijohteet' by handRail_woodType
    if (item.woodType === 'Käsijohteet') {
      if (!acc[item.woodType][item.handRail_woodType]) {
        acc[item.woodType][item.handRail_woodType] = [];
      }
      acc[item.woodType][item.handRail_woodType].push(item);

    } else {
      // If it's a platform (Tasot condition), group under 'Tasot' key within the same woodType
      if (isPlatform) {
        if (!acc[item.woodType]["Tasot"]) {
          acc[item.woodType]["Tasot"] = [];
        }
        acc[item.woodType]["Tasot"].push(item);
      } else {
        // Regular grouping by thickness if not a platform
        if (!acc[item.woodType][item.thickness]) {
          acc[item.woodType][item.thickness] = [];
        }
        acc[item.woodType][item.thickness].push(item);
      }
    }

    return acc;
  }, {});

  // Sort the keys of groupedStorage in reverse alphabetical order
  const sortedGroupedStorage = Object.keys(groupedStorage)
    .sort((a, b) => b.localeCompare(a))
    .reduce((sortedAcc, key) => {
      sortedAcc[key] = groupedStorage[key];
      return sortedAcc;
    }, {});

  // Sort items within each group by length or by handRailModel alphabetically if it's a handrail
  for (const woodType in groupedStorage) {
    for (const groupKey in groupedStorage[woodType]) {
      groupedStorage[woodType][groupKey].sort((a, b) => {
        if (woodType === 'Käsijohteet') {
          return a.handRailModel.localeCompare(b.handRailModel);
        } else {
          return a.length - b.length;
        }
      });
    }
  }

  const renderImageWithButton = (item, selectedWoodType, handleAddImageClick) => {
    const imageUrl = item.image
      ? `data:image/jpeg;base64,${item.image}`
      : selectedWoodType === 'Tammi'
        ? TammiImage
        : selectedWoodType === 'Koivu'
          ? KoivuImage
          : selectedWoodType === 'Saarni'
            ? SaarniImage
            : selectedWoodType === 'Tammi MDF'
              ? MDFTammiImage
              : selectedWoodType === 'Käsijohteet'
                ? getHandRailImage(item.handRail_woodType, item.handRailModel)
                : QuestionMarkImage;

    return (
      <div style={{ position: 'relative' }}>
        <img
          src={imageUrl}
          alt={selectedWoodType}
          style={{ width: '100px', height: '100px', marginBottom: '10px' }}
        />
        {!isModifyAmountMode && (
          <button
            onClick={() => handleAddImageClick(item._id)}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100px',
              height: '100px',
              opacity: 0,
              border: 'none',
              padding: 0,
              margin: 0,
              cursor: 'pointer',
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div>
      <div className="button-grid-container">
        <button className="gradoGreenButton" onClick={handleAddItemClick}>
          + Lisää puutavara
        </button>
        <button className="gradoGreenButton" onClick={handleAddHandRailClick}>
          + Lisää käsijohde
        </button>
        <button className="gradoGreenButton" onClick={handleAddOtherClick}>
          + Lisää muu
        </button>
        <button className="gradoGreenButton" onClick={handleShowTransactionWindow}>
          Näytä tapahtumat
        </button>
        <button
          className="gradoGreenButton"
          onClick={() => setIsModifyAmountMode(!isModifyAmountMode)}
        >
          {isModifyAmountMode ? 'Muokkaa tuotteita' : 'Muokkaa määriä'}
        </button>
        {showTransactionWindow && <TransactionWindow onClose={() => setShowTransactionWindow(false)} />}
      </div>

      <div className="storage-page-container">
        <div className="woodType-buttons">
          {Object.keys(sortedGroupedStorage).map((woodType) => (
            <button
              key={woodType}
              className={`woodType-button ${selectedWoodType === woodType ? 'active' : ''}`}
              onClick={() => handleWoodTypeClick(woodType)}
            >
              {woodType}
            </button>
          ))}
        </div>
        <div className="storage-grid-container" >
          <div style={{ height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {showLoading && <LoadingIcon style={{ fontSize: '1.5em' }} />}
          </div>
          {showMessage && (
            <div className="notification" style={{ position: "absolute", top: "0px" }}>
              {notifications.requestStatus === 'success' && (
                <>
                  <FaCheck className="pulse-spin notification-success" style={{ fontSize: '2em' }} />
                  <span className="notification-text notification-success">{Message}</span>
                </>
              )}
              {notifications.requestStatus === 'error' && (
                <>
                  <FaTimes className="pulse-spin notification-error" style={{ fontSize: '2em' }} />
                  <span className="notification-text notification-error">{Message}</span>
                </>
              )}
            </div>
          )}
          <div className="storage-grid">

          {selectedWoodType && groupedStorage[selectedWoodType] && Object.entries(groupedStorage[selectedWoodType]).map(([groupKey, items]) => (
  <div key={groupKey}>
    <h3 className='bigheader'>
      {items.some(item => item.item) ? items[0].woodType :
        (selectedWoodType === 'Käsijohteet' ? groupKey :
          (!isNaN(groupKey) ? `${groupKey}mm` : groupKey))}
    </h3>
    <div className="items-container">
      {items.map((item, index) => (
        <div
          key={`${item._id}-${index}`}
          className={`storage-item ${item.amount === 0 ? 'red' : item.amount < item.limit ? 'yellow' : 'green'}`}
          onClick={isModifyAmountMode ? () => handleModifyAmount(item._id) : null}
          style={{ cursor: isModifyAmountMode ? 'pointer' : 'default' }}
        >
          {selectedWoodType === 'Käsijohteet' && (
            <div style={{ fontWeight: 'bold' }}>{item.handRailModel}</div>
          )}

          <div>
            {item.item ? (
              <div>
                <div style={{ fontWeight: 'bold' }}>{item.item}</div>
                <div style={{ fontWeight: 'lighter' }}>{item.info}</div>
              </div>
            ) : selectedWoodType === 'Käsijohteet' ? (
              <div>{item.length}mm</div>
            ) : (
              <>
                <div style={{ fontWeight: 'bold' }}>{item.length} x {item.depth}</div>
                <div style={{ fontWeight: 'lighter' }}>{item.thickness}mm</div>
              </>
            )}
          </div>

          {!isModifyAmountMode && selectedWoodType !== '' && (
            renderImageWithButton(item, selectedWoodType, handleAddImageClick)
          )}

          <div>
            {isModifyAmountMode ? (
              <>
                {renderImageWithButton(item, selectedWoodType, handleAddImageClick)}
                {item.amount} kpl
              </>
            ) : (
              <>
                <button onClick={() => handleModifyProduct(item)} className="icon-button">
                  <img src={gearIcon} className="modifyIcons" alt="Modify Product" style={{ marginTop: '-5px' }} />
                </button>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
))}

          </div>
        </div>

        {showAddItem && (
          <AddItem
            onSubmit={(formData, person) => {
              dispatch(addStorageItem(formData, person));
              setShowAddItem(false);
            }}
            onClose={() => setShowAddItem(false)}
          />
        )}
        {showAddHandRail &&
          <AddHandRail
            onSubmit={(formData, person) => {
              dispatch(addStorageItem(formData, person));
              setShowAddHandRail(false);
            }}
            onClose={() => setShowAddHandRail(false)}
          />}
        {showAddOther && (
          <AddOther
            onSubmit={(formData, person) => {
              dispatch(addStorageItem(formData, person));
              setShowAddOther(false);
            }}
            onClose={() => setShowAddOther(false)}
          />
        )}

        {showModifyProduct && (
          <ModifyProduct
            key={selectedItem._id}
            formData={selectedItem}
            onSubmit={(formData, person) => {
              dispatch(updateStorageItem(formData._id, formData, selectedItem, 'Muokattu', person));
              setShowModifyProduct(false);
            }}
            onDelete={(itemId, person) => {
              dispatch(deleteStorageItem(itemId, selectedItem, person));
              setShowModifyProduct(false);
            }}
            onClose={() => setShowModifyProduct(false)}
            onAddition={(formData, person) => {
              dispatch(addStorageItem(formData, person));
            }}
          />
        )}

        {showModifyAmount && (
          <ModifyAmount
            key={selectedItemId._id}
            initialAmount={storage.find(item => item._id === selectedItemId)?.amount} // Get the initial amount from the selected item
            onSubmit={(newAmount, item, person) => {
              // Update the storage item amount
              dispatch(updateStorageItem(selectedItemId, { amount: newAmount }, item, 'ModifyAmount', person));
              setShowModifyAmount(false);
            }}
            onCancel={() => setShowModifyAmount(false)}
            item={storage.find(item => item._id === selectedItemId)}
          />
        )}

      </div>
    </div>
  );
};

export default StoragePage;
