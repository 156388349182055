import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import gradoLogo from '../images/grado.png'; // Update the path to your grado.png file
import { logoutUser } from '../redux/actions/thunks/Auth.js'; // Import logoutUser thunk
import { orderModified } from '../redux/actions/ordersAction.js';
import { FaEllipsisV } from 'react-icons/fa'; // Import the 3 dots icon

const NavigationBar = () => {
  const { pathname } = useLocation();
  const currentUser = useSelector(state => state.users.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderState = useSelector(state => state.orders.isModified);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to handle dropdown visibility

  const token = localStorage.getItem('token');

  if (!token) {
    return null;
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
    setIsDropdownOpen(false);
  };

  const handleNavigation = (e) => {
    if (orderState && !window.confirm('Muokkauksia ei ole tallennettu. Haluatko poistua sivulta tallentamatta?')) {
      e.preventDefault();
    } else if (orderState) {
      dispatch(orderModified(false)); // Set orderState to false when confirmed
    }
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav className="navbar dot-matrix">
      <Link to="/calendar" onClick={handleNavigation}>
        <img src={gradoLogo} alt="Grado Logo" className="navbar-logo" />
      </Link>
      <ul className="navbar-list">
        <li className="navbar-item">
          <Link to="/calendar" className={`navbar-link ${pathname === '/calendar' ? 'active' : ''}`} id="calendar-link" onClick={handleNavigation}>
            KALENTERI
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/storage" className={`navbar-link ${pathname === '/storage' ? 'active' : ''}`} id="storage-link" onClick={handleNavigation}>
            VARASTO
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/orders" className={`navbar-link ${pathname === '/orders' ? 'active' : ''}`} id="orders-link" onClick={handleNavigation}>
            TILAUKSET
          </Link>
        </li>
        <li className="navbar-item">
          <Link to="/colors" className={`navbar-link ${pathname === '/colors' ? 'active' : ''}`} id="colors-link" onClick={handleNavigation}>
            SÄVYT
          </Link>
        </li>
      </ul>
      <div className="navbar-dropdown">
        <button className="navbar-menu-button" onClick={toggleDropdown}>
          <FaEllipsisV />
        </button>
        {isDropdownOpen && (
          <div className="dropdown-menu">
            {currentUser && (
              <div style={{padding: '15px'}}>
                <span>Kirjautuneena: {currentUser.username}</span>
              </div>
            )}
            {(currentUser.role === 'admin' || currentUser.role === 'design') && (
            <Link to="/resources" className={`navbar-link ${pathname === '/resources' ? 'active' : ''}`} onClick={toggleDropdown}>
              RESURSSIT
            </Link>
            )}
            {currentUser.role === 'admin' && (
              <Link to="/users" className={`navbar-link ${pathname === '/users' ? 'active' : ''}`} onClick={toggleDropdown}>
                KÄYTTÄJÄT
              </Link>
            )}
            <div style={{padding: '15px'}}>
            <button style={{borderRadius: '5px', padding: '5px', background: 'transparent'}} className="dropdown-item" onClick={() => { handleLogout(); toggleDropdown(); }}>
              Kirjaudu ulos
            </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavigationBar;
