import React, { useState, useEffect } from 'react';

const FloorModal = ({ onSubmit, onCancel, existingFloor }) => {
  const [formData, setFormData] = useState({
    floor: existingFloor ? existingFloor.floor : '', // Ensure 'floor' exists, not 'name'
    materials: existingFloor ? existingFloor.materials : '',
  });

  // Sync form data with the existingFloor prop if it changes
  useEffect(() => {
    if (existingFloor) {
      setFormData({
        floor: existingFloor.floor || '', // Ensure 'floor' is present
        materials: existingFloor.materials || '',
      });
    }
  }, [existingFloor]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updatedData = { ...formData }; // Gather form data
    onSubmit(updatedData); // Pass the updated data to the parent handler
    onCancel(); // Close modal after submission
  };

  const handleCancel = () => {
    console.log("suljetaan modalissa");
    onCancel(); // Close modal on cancel
  };

  return (
    <div className="add-item-modal">
      <div className="add-item-window">
        <h2 style={{ textAlign: 'center' }}>{existingFloor ? 'Päivitä sävyä' : 'Lisää sävy'}</h2>
        <form onSubmit={handleSubmit} className="add-item-form">
          <div className="form-group">
            <label htmlFor="floor-input">Sävy:</label>
            <input
              type="text"
              name="floor"
              value={formData.floor}
              onChange={handleChange}
              required
              className="form-input"
            />
            <label htmlFor="materials-input">Pintakäsittelymateriaalit:</label>
            <input
              type="text"
              name="materials"
              value={formData.materials}
              onChange={handleChange}
              required
              className="form-input"
            />
          </div>
          <div className="form-actions">
            <button type="submit" className="form-button form-button-new">
              {existingFloor ? 'Päivitä sävy' : 'Lisää sävy'}
            </button>
            <button type="button" onClick={handleCancel} className="form-button form-button-cancel">
              Peru
            </button>
          </div>
        </form>
      </div>
    </div>

  );
};

export default FloorModal;
