import {
    ADD_MANUFACTURER_REQUEST,
    ADD_MANUFACTURER_SUCCESS,
    ADD_MANUFACTURER_FAILURE,
    ADD_FLOOR_REQUEST,
    ADD_FLOOR_SUCCESS,
    ADD_FLOOR_FAILURE,
    UPDATE_FLOOR_REQUEST,
    UPDATE_FLOOR_SUCCESS,
    UPDATE_FLOOR_FAILURE,
    DELETE_FLOOR_REQUEST,
    DELETE_FLOOR_SUCCESS,
    DELETE_FLOOR_FAILURE,
    FETCH_MANUFACTURERS_REQUEST,
    FETCH_MANUFACTURERS_SUCCESS,
    FETCH_MANUFACTURERS_FAILURE,
    UPDATE_MANUFACTURER_REQUEST,
    UPDATE_MANUFACTURER_SUCCESS,
    UPDATE_MANUFACTURER_FAILURE,
    DELETE_MANUFACTURER_REQUEST,
    DELETE_MANUFACTURER_SUCCESS,
    DELETE_MANUFACTURER_FAILURE,
} from '../actionTypes';

const initialState = {
    manufacturers: [],
    loading: false,
    error: null,
};

const floorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_MANUFACTURER_REQUEST:
        case ADD_FLOOR_REQUEST:
        case UPDATE_FLOOR_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case ADD_MANUFACTURER_SUCCESS:
            const newManufacturer = {
                manufacturer: action.payload.name,
                floors: [],
                _id: action.payload.id, // Ensure ID is generated in the backend
                __v: 0
              };
              return {
                ...state,
                manufacturers: [...state.manufacturers, newManufacturer]
              };

        case ADD_FLOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                manufacturers: state.manufacturers.map((manufacturer) =>
                    manufacturer.manufacturer === action.payload.manufacturer
                        ? {
                            ...manufacturer,
                            floors: [...manufacturer.floors, action.payload.floor],
                        }
                        : manufacturer
                ),
            };

        case UPDATE_FLOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                manufacturers: state.manufacturers.map((manufacturer) =>
                    manufacturer.manufacturer === action.payload.manufacturer
                        ? {
                            ...manufacturer,
                            floors: manufacturer.floors.map((floor) =>
                                floor.floor === action.payload.oldFloorName
                                    ? action.payload.updatedFloor
                                    : floor
                            ),
                        }
                        : manufacturer
                ),
            };

        case ADD_MANUFACTURER_FAILURE:
        case ADD_FLOOR_FAILURE:
        case UPDATE_FLOOR_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case DELETE_FLOOR_REQUEST:
            return { ...state, loading: true };

        case DELETE_FLOOR_SUCCESS:
            return {
                ...state,
                loading: false,
                manufacturers: state.manufacturers.map((manufacturer) =>
                    manufacturer.name === action.payload.manufacturer
                        ? {
                            ...manufacturer,
                            floors: manufacturer.floors.filter(
                                (floor) => floor.name !== action.payload.floorName
                            ),
                        }
                        : manufacturer
                ),
            };

        case DELETE_FLOOR_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case FETCH_MANUFACTURERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case FETCH_MANUFACTURERS_SUCCESS:
            return {
                ...state,
                loading: false,
                manufacturers: action.payload,
            };

        case FETCH_MANUFACTURERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
            case DELETE_MANUFACTURER_REQUEST:
                case UPDATE_MANUFACTURER_REQUEST:
                  return {
                    ...state,
                    loading: true,
                  };
                case DELETE_MANUFACTURER_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    manufacturers: state.manufacturers.filter(
                      (manufacturer) => manufacturer._id !== action.payload
                    ),
                  };
                case UPDATE_MANUFACTURER_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    manufacturers: state.manufacturers.map((manufacturer) =>
                      manufacturer._id === action.payload._id ? action.payload : manufacturer
                    ),
                  };
                case DELETE_MANUFACTURER_FAILURE:
                case UPDATE_MANUFACTURER_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.payload,
                  };


        default:
            return state;
    }
};

export default floorsReducer;
