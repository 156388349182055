export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const FETCH_OPEN_ORDERS_REQUEST = 'FETCH_OPEN_ORDERS_REQUEST';
export const FETCH_OPEN_ORDERS_SUCCESS = 'FETCH_OPEN_ORDERS_SUCCESS';
export const FETCH_OPEN_ORDERS_FAILURE = 'FETCH_OPEN_ORDERS_FAILURE';

export const FETCH_ALL_ORDERS_REQUEST = 'FETCH_ALL_ORDERS_REQUEST';
export const FETCH_ALL_ORDERS_SUCCESS = 'FETCH_ALL_ORDERS_SUCCESS';
export const FETCH_ALL_ORDERS_FAILURE = 'FETCH_ALL_ORDERS_FAILURE';

export const FETCH_STORAGE_REQUEST = 'FETCH_STORAGE_REQUEST';
export const FETCH_STORAGE_SUCCESS = 'FETCH_STORAGE_SUCCESS';
export const FETCH_STORAGE_FAILURE = 'FETCH_STORAGE_FAILURE';

export const ADD_STORAGE_ITEM_REQUEST = 'ADD_STORAGE_ITEM_REQUEST';
export const ADD_STORAGE_ITEM_SUCCESS = 'ADD_STORAGE_ITEM_SUCCESS';
export const ADD_STORAGE_ITEM_FAILURE = 'ADD_STORAGE_ITEM_FAILURE';

export const UPDATE_STORAGE_ITEM_REQUEST = 'UPDATE_STORAGE_ITEM_REQUEST';
export const UPDATE_STORAGE_ITEM_SUCCESS = 'UPDATE_STORAGE_ITEM_SUCCESS';
export const UPDATE_STORAGE_ITEM_FAILURE = 'UPDATE_STORAGE_ITEM_FAILURE';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_FAILURE = 'UPDATE_ORDER_FAILURE';

export const ORDER_MODIFICATION = 'ORDER_MODIFICATION';

export const CLEAR_ORDERS = 'CLEAR_ORDERS'

export const DELETE_STORAGE_ITEM_SUCCESS = 'DELETE_STORAGE_ITEM_SUCCESS';
export const DELETE_STORAGE_ITEM_FAILURE = 'DELETE_STORAGE_ITEM_FAILURE';
export const DELETE_STORAGE_ITEM_REQUEST = 'DELETE_STORAGE_ITEM_REQUEST';

export const FETCH_SPENT_RESOURCES_REQUEST = 'FETCH_SPENT_RESOURCES_REQUEST';
export const FETCH_SPENT_RESOURCES_SUCCESS = 'FETCH_SPENT_RESOURCES_SUCCESS';
export const FETCH_SPENT_RESOURCES_FAILURE = 'FETCH_SPENT_RESOURCES_FAILURE';
export const UPDATE_SPENT_RESOURCES_REQUEST = 'UPDATE_SPENT_RESOURCES_REQUEST';
export const UPDATE_SPENT_RESOURCES_SUCCESS = 'UPDATE_SPENT_RESOURCES_SUCCESS';
export const UPDATE_SPENT_RESOURCES_FAILURE = 'UPDATE_SPENT_RESOURCES_FAILURE';

export const FETCH_WEEKS_REQUEST = 'FETCH_WEEKS_REQUEST';
export const FETCH_WEEKS_SUCCESS = 'FETCH_WEEKS_SUCCESS';
export const FETCH_WEEKS_FAILURE = 'FETCH_WEEKS_FAILURE';

export const UPDATE_WEEK_REQUEST = 'UPDATE_WEEK_REQUEST';
export const UPDATE_WEEK_SUCCESS = 'UPDATE_WEEK_SUCCESS';
export const UPDATE_WEEK_FAILURE = 'UPDATE_WEEK_FAILURE';

export const FETCH_TRANSACTIONS_REQUEST = 'FETCH_TRANSACTIONS_REQUEST';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const ADD_TRANSACTION_REQUEST = 'ADD_TRANSACTION_REQUEST';
export const ADD_TRANSACTION_SUCCESS = 'ADD_TRANSACTION_SUCCESS';
export const ADD_TRANSACTION_FAILURE = 'ADD_TRANSACTION_FAILURE';

// User action types
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const FETCH_USER_COUNT_REQUEST = 'FETCH_USER_COUNT_REQUEST';
export const FETCH_USER_COUNT_SUCCESS = 'FETCH_USER_COUNT_SUCCESS';
export const FETCH_USER_COUNT_FAILURE = 'FETCH_USER_COUNT_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_NEW_USER_SUCCESS = 'ADD_NEW_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const FETCH_STORAGE_PREDICTION_REQUEST = 'FETCH_STORAGE_PREDICTION_REQUEST';
export const FETCH_STORAGE_PREDICTION_SUCCESS = 'FETCH_STORAGE_PREDICTION_SUCCESS';
export const FETCH_STORAGE_PREDICTION_FAILURE = 'FETCH_STORAGE_PREDICTION_FAILURE';
export const FETCH_RESOURCE_PREDICTION_REQUEST = 'FETCH_STORAGE_RESOURCE_REQUEST';
export const FETCH_RESOURCE_PREDICTION_SUCCESS = 'FETCH_STORAGE_RESOURCE_SUCCESS';
export const FETCH_RESOURCE_PREDICTION_FAILURE = 'FETCH_STORAGE_RESOURCE_FAILURE';

export const UPLOAD_ITEM_IMAGE_REQUEST = 'UPLOAD_ITEM_IMAGE_REQUEST';
export const UPLOAD_ITEM_IMAGE_SUCCESS = 'UPLOAD_ITEM_IMAGE_SUCCESS';
export const UPLOAD_ITEM_IMAGE_FAILURE = 'UPLOAD_ITEM_IMAGE_FAILURE';

export const FETCH_QUOTE_REQUEST = 'FETCH_QUOTE_REQUEST';
export const FETCH_QUOTE_SUCCESS = 'FETCH_QUOTE_SUCCESS';
export const FETCH_QUOTE_FAILURE = 'FETCH_QUOTE_FAILURE';

// Manufacturer Actions
export const ADD_MANUFACTURER_REQUEST = 'ADD_MANUFACTURER_REQUEST';
export const ADD_MANUFACTURER_SUCCESS = 'ADD_MANUFACTURER_SUCCESS';
export const ADD_MANUFACTURER_FAILURE = 'ADD_MANUFACTURER_FAILURE';

export const FETCH_MANUFACTURERS_REQUEST = 'FETCH_MANUFACTURERS_REQUEST';
export const FETCH_MANUFACTURERS_SUCCESS = 'FETCH_MANUFACTURERS_SUCCESS';
export const FETCH_MANUFACTURERS_FAILURE = 'FETCH_MANUFACTURERS_FAILURE';

export const UPDATE_MANUFACTURER_REQUEST = 'FETCH_MANUFACTURER_REQUEST';
export const UPDATE_MANUFACTURER_SUCCESS = 'FETCH_MANUFACTURER_SUCCESS';
export const UPDATE_MANUFACTURER_FAILURE = 'FETCH_MANUFACTURER_FAILURE';

export const DELETE_MANUFACTURER_REQUEST = 'FETCH_MANUFACTURER_REQUEST';
export const DELETE_MANUFACTURER_SUCCESS = 'FETCH_MANUFACTURER_SUCCESS';
export const DELETE_MANUFACTURER_FAILURE = 'FETCH_MANUFACTURER_FAILURE';

// Floor Actions
export const ADD_FLOOR_REQUEST = 'ADD_FLOOR_REQUEST';
export const ADD_FLOOR_SUCCESS = 'ADD_FLOOR_SUCCESS';
export const ADD_FLOOR_FAILURE = 'ADD_FLOOR_FAILURE';

export const UPDATE_FLOOR_REQUEST = 'UPDATE_FLOOR_REQUEST';
export const UPDATE_FLOOR_SUCCESS = 'UPDATE_FLOOR_SUCCESS';
export const UPDATE_FLOOR_FAILURE = 'UPDATE_FLOOR_FAILURE';

export const DELETE_FLOOR_REQUEST = 'DELETE_FLOOR_REQUEST';
export const DELETE_FLOOR_SUCCESS = 'DELETE_FLOOR_SUCCESS';
export const DELETE_FLOOR_FAILURE = 'DELETE_FLOOR_FAILURE';