import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchManufacturers,
  addManufacturer,
  addFloor,
  updateFloor,
  deleteFloor,
  deleteManufacturer,
  updateManufacturer,
} from '../redux/actions/thunks/Floor.js';
import NameInputModal from './NameInputModal';
import FloorModal from './FloorModal';
import DeleteConfirmation from './DeleteConfirmation';
import { FaCheck, FaTimes } from 'react-icons/fa';
import LoadingIcon from './LoadingIcon';
import SearchBar from './SearchBar';

const ManufacturerPage = () => {
  const [showManufacturerModal, setShowManufacturerModal] = useState(false);
  const [showFloorModal, setShowFloorModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModifyManufacturerModal, setShowModifyManufacturerModal] = useState(false);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [isDeletingManufacturer, setIsDeletingManufacturer] = useState(false);
  const [visibleFloorIcons, setVisibleFloorIcons] = useState(null);
  const [visibleManufacturerIcons, setVisibleManufacturerIcons] = useState(null);
  const [Message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const dispatch = useDispatch();

  const manufacturers = useSelector((state) => state.floors.manufacturers);
  const notifications = useSelector(state => state.notification);

  useEffect(() => {
    const successMessages = [
      'Valmistaja lisätty onnistuneesti',
      'Sävy lisätty valmistajalle onnistuneesti',
      'Sävyn tiedot päivitettiin onnistuneesti',
      'Sävy poistettu valmistajalta onnistuneesti',
      'Valmistaja poistettu onnistuneesti',
      'Valmistaja päivitetty onnistuneesti',
    ];

    const failureMessages = [
      'Valmistajien haku epäonnistui',
      'Valmistajan lisääminen epäonnistui',
      'Sävyn lisääminen epäonnistui',
      'Sävyn päivitys epäonnistui',
      'Sävyn poistaminen epäonnistui',
      'Valmistajan poistaminen epäonnistui',
      'Valmistajan päivittäminen epäonnistui',
    ];

    if (successMessages.includes(notifications.message) || failureMessages.includes(notifications.message)) {
      setMessage(notifications.message);
      setShowMessage(true);

      setTimeout(() => {
        setShowMessage(false);
      }, 5000);
    }
  }, [notifications]);

  useEffect(() => {
    setShowLoading(false);
    if (notifications.requestStatus === 'loading') {
      setShowLoading(true);
    }
  }, [notifications]);

  useEffect(() => {
    dispatch(fetchManufacturers());
    console.log(manufacturers);
  }, [dispatch]);

  const handleToggleIcons = (floorId) => {
    setVisibleFloorIcons((prev) => (prev === floorId ? null : floorId));
  };

  const handleToggleManufacturerIcons = (manufacturerId) => {
    setVisibleManufacturerIcons((prev) => (prev === manufacturerId ? null : manufacturerId));
  };
  const handleToggleFavorite = (manufacturer) => {
    dispatch(updateManufacturer(manufacturer._id, { isFavorite: !manufacturer.isFavorite })).then(() => {
      dispatch(fetchManufacturers());
    });
  };

  const handleAddManufacturer = () => {
    setShowManufacturerModal(true);
  };

  const handleModifyManufacturer = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setShowModifyManufacturerModal(true);
  };

  const handleModifyManufacturerConfirm = (newName) => {
    const duplicate = manufacturers.some(
      (manufacturer) => manufacturer.manufacturer.toLowerCase() === newName.toLowerCase()
    );
    if (duplicate) {
      alert('Tämä valmistaja on jo olemassa');
      return;
    }
    if (selectedManufacturer) {
      dispatch(updateManufacturer(selectedManufacturer._id, { manufacturer: newName })).then(() => {
        dispatch(fetchManufacturers());
      });
      setShowModifyManufacturerModal(false);
      setSelectedManufacturer(null);
    }
  };

  const handleDeleteManufacturerRequest = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setIsDeletingManufacturer(true);
    setShowDeleteModal(true);
  };

  const handleDeleteManufacturerConfirm = () => {
    dispatch(deleteManufacturer(selectedManufacturer._id)).then(() => {
      dispatch(fetchManufacturers());
    });
    setShowDeleteModal(false);
    setSelectedManufacturer(null);
  };

  const handleAddFloor = (manufacturer) => {
    setSelectedManufacturer(manufacturer);
    setShowFloorModal(true);
  };

  const handleFloorModify = (manufacturer, floor) => {
    setSelectedManufacturer(manufacturer);
    setSelectedFloor(floor);
    setShowFloorModal(true);
  };

  const handleFloorConfirm = (floorData) => {
    const duplicate = selectedManufacturer.floors.some(
      (floor) =>
        floor.floor.toLowerCase() === floorData.floor.toLowerCase() &&
        floor._id !== selectedFloor?._id // Exclude the current floor being modified
    );

    if (duplicate) {
      alert('Tämä sävy on jo olemassa');
      return; // Exit early to prevent closing the modal
    }

    if (selectedFloor) {
      // Modify the existing floor
      dispatch(updateFloor(selectedManufacturer, selectedFloor, floorData)).then(() => {
        dispatch(fetchManufacturers());
      });
    } else {
      // Add a new floor
      dispatch(addFloor(selectedManufacturer, floorData)).then(() => {
        dispatch(fetchManufacturers());
      });
    }
    setShowFloorModal(false); // Close the modal only after successful processing
  };


  const handleFloorDeleteRequest = (manufacturer, floor) => {
    setSelectedManufacturer(manufacturer);
    setSelectedFloor(floor);
    setShowDeleteModal(true);
    setIsDeletingManufacturer(false);
  };

  const handleFloorDeleteConfirm = () => {
    dispatch(deleteFloor(selectedManufacturer, selectedFloor)).then(() => {
      dispatch(fetchManufacturers());
    });
    setShowDeleteModal(false);
    setSelectedManufacturer(null);
    setSelectedFloor(null);
  };

  const handleCancel = () => {
    setShowManufacturerModal(false);
    setShowFloorModal(false);
    setShowDeleteModal(false);
    setShowModifyManufacturerModal(false);
    setSelectedManufacturer(null);
    setSelectedFloor(null);
  };

  const handleManufacturerConfirm = (name) => {
    const duplicate = manufacturers.some(
      (manufacturer) => manufacturer.manufacturer.toLowerCase() === name.toLowerCase()
    );
    if (duplicate) {
      alert('Tämä valmistaja on jo olemassa');
      return;
    }
    dispatch(addManufacturer(name)).then(() => {
      dispatch(fetchManufacturers());
    });
    setShowManufacturerModal(false);
  };

  // Function to handle search term change
  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  // Filter manufacturers based on the floor's name 
  const filteredManufacturers = manufacturers.map((manufacturer) => {
    // Filter the floors based on the search term
    const filteredFloors = manufacturer.floors.filter((floor) =>
      floor.floor.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Return the manufacturer with the filtered floors (or all floors if no search term)
    return {
      ...manufacturer,
      floors: filteredFloors,  // Only include matching floors
    };
  });

  // Now, filter out manufacturers that have no floors left after filtering
  const manufacturersWithFloors = filteredManufacturers.filter(
    (manufacturer) => manufacturer.floors.length > 0 || !searchTerm
  );


  const sortedManufacturers = [...manufacturersWithFloors].sort((a, b) => {
    const aName = a.manufacturer || '';
    const bName = b.manufacturer || '';
    return b.isFavorite - a.isFavorite || aName.localeCompare(bName);
  });


  return (
    <div className="manufacturer-page">
      <h1>Lattiavalmistajat ja sävyt</h1>
      <button className="btn-add" onClick={handleAddManufacturer}>
        + Lisää valmistaja
      </button>
      <SearchBar placeholder="Hae sävyä..." handleChange={handleSearchChange} />
      <div style={{ height: '20px', display: 'flex' }}>
        <div >
          {showLoading && <LoadingIcon style={{ fontSize: '1.5em' }} />}
        </div>
        <div className="notification-container">
          {showMessage && (
            <div className="notification">
              {notifications.requestStatus === 'success' && (
                <>
                  <FaCheck className="pulse-spin notification-success" style={{ fontSize: '2em' }} />
                  <span className="notification-text notification-success">{Message}</span>
                </>
              )}
              {notifications.requestStatus === 'error' && (
                <>
                  <FaTimes className="pulse-spin notification-error" style={{ fontSize: '2em' }} />
                  <span className="notification-text notification-error">{Message}</span>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {sortedManufacturers.length === 0 ? (
        <div className="no-manufacturers-message">
          <p>Sävyjä ei löytynyt</p>
        </div>
      ) : (
        /* Render manufacturers */
        Array.isArray(sortedManufacturers) &&
        sortedManufacturers.map((manufacturer, index) => (
          <div key={manufacturer._id || `manufacturer-${index}`} className="manufacturer-item">
            <div className="manufacturer-header" onClick={() => handleToggleManufacturerIcons(manufacturer._id)}>
              <button
                onClick={() => handleToggleFavorite(manufacturer)}
                className={`favorite-btn ${manufacturer.isFavorite ? 'active' : ''}`}
              >
                ★
              </button>
              <h2>{manufacturer.manufacturer}</h2>

              <div className="manufacturer-actions">
                <button onClick={() => handleAddFloor(manufacturer)} className="btn-add-floor">
                  + Lisää sävy
                </button>
              </div>

              {/* Icons for Manufacturer */}
              <div className={`icon-container ${visibleManufacturerIcons === manufacturer._id ? 'visible' : ''}`}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteManufacturerRequest(manufacturer);
                  }}
                  className="icon-btn"
                >
                  🗑️
                </button>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleModifyManufacturer(manufacturer);
                  }}
                  className="icon-btn"
                >
                  ✏️
                </button>
              </div>
            </div>

            <ul>
              {manufacturer.floors
                .sort((a, b) => a.floor.localeCompare(b.floor))
                .map((floor) => (
                  <li
                    key={floor._id}
                    className={`floor-item ${visibleFloorIcons === floor._id ? 'active' : ''}`}
                    onClick={() => handleToggleIcons(floor._id)}
                  >
                    <span className="floor-name">{floor.floor}</span> - {Array.isArray(floor.materials) ? floor.materials.join(', ') : floor.materials}
                    <div className={`icon-container ${visibleFloorIcons === floor._id ? 'visible' : ''}`}>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the click handler for the list item
                          handleFloorDeleteRequest(manufacturer, floor);
                        }}
                        className="icon-btn"
                      >
                        🗑️
                      </button>
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent triggering the click handler for the list item
                          handleFloorModify(manufacturer, floor);
                        }}
                        className="icon-btn"
                      >
                        ✏️
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        ))
      )}

      {/* Modals */}
      {showManufacturerModal && (
        <NameInputModal
          item=""
          onConfirm={handleManufacturerConfirm}
          onCancel={handleCancel}
        />
      )}

      {showModifyManufacturerModal && (
        <NameInputModal
          item=""
          initialValue={selectedManufacturer?.manufacturer}
          onConfirm={handleModifyManufacturerConfirm}
          onCancel={handleCancel}
        />
      )}

      {showFloorModal && (
        <FloorModal
          onSubmit={handleFloorConfirm}
          onCancel={handleCancel}
          existingFloor={selectedFloor}
        />
      )}

      {showDeleteModal && (
        <DeleteConfirmation
          item={isDeletingManufacturer ? `${selectedManufacturer?.manufacturer}` : `${selectedFloor?.floor}`}
          onConfirm={isDeletingManufacturer ? handleDeleteManufacturerConfirm : handleFloorDeleteConfirm}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default ManufacturerPage;
